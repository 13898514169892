<template>
  <div class="container">
    <PublicHeader :totalName="$t('register').title" :noLeft="true" />
    <!-- 输入框 -->
    <div class="content">
      <div class="form">
        <!-- 手机号注册 -->
        <div class="item">
          <div class="lable">{{ $t('register').PhoneNumber }}</div>
          <van-field v-model="PhoneNumber" type="tel" :placeholder="$t('register').placeholder0">
            <template #left-icon>
              <i class="select_btn">
                <VueCountryIntl schema="popover" v-model="PhoneNumberCode" :listZIndex="10000"
                  popoverClass="selectCountry" :searchInputPlaceholder="$t('register').searchCountryMsg"
                  @onChange="selectCountry">
                  <div slot="reference" @click="selectCountry">
                    +{{ PhoneNumberCode }}
                  </div>
                </VueCountryIntl>
              </i>
            </template>
          </van-field>
        </div>
        <div class="item" v-if="RegisterNeedSmsCode">
          <div class="lable">{{ $t('register').Captcha }}</div>
          <van-field v-model="Captcha" :placeholder="$t('register').placeholder4">
            <template #right-icon>
              <span style="color: #333333" @click="getSmsCode" v-if="countDownSec === 60">{{ tips }}</span>
              <span v-else style="color: #515151">{{ countDownSec }} s</span>
            </template>
          </van-field>
        </div>
        <div class="item">
          <div class="lable">{{ $t('register').Password }}</div>
          <van-field v-model="password" :type="psdType" :placeholder="$t('register').placeholder1"
            :right-icon="psdType === 'password' ? 'eye' : 'closed-eye'"
            @click-right-icon="psdType = psdType === 'password' ? 'text' : 'password'">
          </van-field>
        </div>
        <div class="item">
          <div class="lable">{{ $t('register').ConfirmPassword }}</div>
          <van-field v-model="twoPassword" :type="twopsdType" :placeholder="$t('register').placeholder2"
            :right-icon="twopsdType === 'password' ? 'eye' : 'closed-eye'"
            @click-right-icon="twopsdType = twopsdType === 'password' ? 'text' : 'password'">
          </van-field>
        </div>
        <div class="item">
          <div class="lable">{{ $t('register').ParentId }}</div>
          <van-field v-model="ParentId" :placeholder="$t('register').placeholder3">
          </van-field>
        </div>
        <!-- 注册按钮 -->
        <van-button color="#C92B2E" block @click="handleSub" style="border-radius: 5px;height: 40px;">{{
          $t('register').btn
        }}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import systemApi from '@/api/system'
import userApi from '@/api/user'
import { mapGetters } from 'vuex'
import { getRandomString } from '@/utils/validate'
import PublicHeader from '@/components/publicHeader'

export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      PhoneNumberCode: '86', //手机区号
      PhoneNumber: '', //手机号
      ParentId: '', //推荐人ID
      Captcha: '', //验证码
      CaptchaToken: '',//短信验证码token
      password: '',//密码
      twoPassword: '',//确认密码
      psdType: "password", //输入框类型
      twopsdType: "password", //输入框类型
      tips: this.$t('register').send,//发送验证码信息
      countDownSec: 60,
      timer: null,
      RegisterNeedInvitationCode: false,//是否需要推荐人ID
      RegisterNeedSmsCode: false,//是否需要手机验证码
      RegisterDownUrl: '',//下载链接
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  mounted() {
    if (this.$route.query.pid) {
      this.ParentId = this.$route.query.pid
    }
    this.getClientConfig()
  },
  methods: {
    //获取客户端配置
    async getClientConfig() {
      const res = await systemApi.getClientConfig()
      this.RegisterNeedInvitationCode = res.Data.RegisterNeedInvitationCode
      this.RegisterNeedSmsCode = res.Data.RegisterNeedSmsCode
      this.RegisterDownUrl = res.Data.RegisterDownUrl
    },
    selectCountry() {
      this.$nextTick(() => {
        const searchInput = document.querySelector(
          '.selectCountry .search-input'
        )
        if (searchInput) {
          searchInput.style.width = '90%'
          searchInput.style.height = 'auto'
        }
      })
    },
    async sendSms() {
      //发送短信
      const pRes = await userApi.getCaptchaSms({
        PhoneNumber: this.PhoneNumber,
        PhoneCode: '+' + this.PhoneNumberCode,
        ImgCaptcha: this.imageCodeTxt,
        ImgToken: this.imgCodeTokenTc,
        PhoneNumberToken: getRandomString(),
        SmsType: 'Register',
      })
      this.$toast({ message: this.$t('register').sendSuccess })
      this.CaptchaToken = pRes.Data.Token
      this.timer = setInterval(() => {
        this.countDownSec--
        if (this.countDownSec === 0) {
          this.countDownSec = 60
          this.tips = this.$t('register').Resend
          clearInterval(this.timer)
          this.timer = null
        }
      }, 1000)
    },
    //获取验证码
    async getSmsCode() {
      // 手机号注册
      if (this.timer || this.PhoneNumber == '') {
        if (this.PhoneNumber == '') {
          this.$toast({
            message: this.$t('register').placeholder0,
            position: 'center'
          })
        }
        return
      }
      this.sendSms()
    },
    async handleSub() {
      if (!this.checkForm()) return
      const form = {
        Password: this.password,
        PhoneNumberCode: this.PhoneNumberCode
      }
      this.$set(form, 'Account', '')
      this.$set(form, 'PhoneNumber', this.PhoneNumber)
      this.$set(form, 'Type', 'PhoneNumber')
      this.$set(form, 'Captcha', this.Captcha)
      this.$set(form, 'CaptchaToken', this.CaptchaToken)
      this.$set(form, 'ParentId', this.ParentId)
      await userApi.Register(form)
      this.$toast(this.$t('register').success)
      setTimeout(() => {
        location.href = this.RegisterDownUrl
      }, 1000)
    },
    // async handleLogin() {
    //   let res = await userApi.getRefreshToken({
    //     username: this.userName,
    //     password: this.Password
    //   })
    //   res = await userApi.login(res.Token)
    //   Token.set(res.Token)
    //   EventBus.$emit('Login')

    //   setTimeout(() => {
    //     this.$router.push({ name: 'Home' })
    //   }, 1000)
    // },
    checkForm() {
      if (!this.PhoneNumber) {
        this.$toast({
          message: this.$t('register').placeholder0,
          position: 'center'
        })
        return false
      }

      if (this.RegisterNeedSmsCode) {
        if (!this.Captcha) {
          this.$toast({
            message: this.$t('register').placeholder4,
            position: 'center'
          })
          return false
        }
      }

      if (this.password.length < 6) {
        this.$toast({
          message: this.$t('register').message,
          position: 'center'
        })
        return false
      }
      if (this.twoPassword.length < 6) {
        this.$toast({
          message: this.$t('register').message1,
          position: 'center'
        })
        return false
      }
      if (this.password != this.twoPassword) {
        this.$toast({
          message: this.$t('register').message2,
          position: 'center'
        })
        return false
      }
      if (this.RegisterNeedInvitationCode) {
        if (!this.ParentId) {
          this.$toast({
            message: this.$t('register').placeholder3,
            position: 'center'
          })
          return false
        }
      }
      return true
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  width: 100%;
  padding: 90px 15px 0 15px;
  background: #ffffff;

  .content {
    padding: 0 10px;

    .form {
      background: #ffffff;
      border-radius: 4px;
      padding: 10px;

      .lable {
        font-weight: bold;
        font-size: 16px;
        color: #333333;
        margin: 20px 0 10px 0;
      }

      .select_btn {
        margin-right: 10px;
      }

      .van-button {
        height: 37px;
        margin-bottom: 10px;
        margin-top: 90px;
      }
    }
  }
}

::v-deep .van-field__value {
  overflow: visible;
  display: grid;
}

::v-deep .van-button__content {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
}

.van-cell {
  background: #F2F2F2;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 15px;
}

::v-deep .van-field__right-icon {
  margin: -10px 8px;
  padding: 0px;
  line-height: normal;
}
</style>
